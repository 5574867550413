import {Injectable} from '@angular/core';
import {KeycloakService} from 'keycloak-angular';
import {BehaviorSubject} from 'rxjs';
import {User} from '@app-models';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private user$ = new BehaviorSubject<User>({});

  constructor(protected readonly keycloak: KeycloakService) {
    this.keycloak.loadUserProfile().then((user) => {
      this.setUser(user);
    });
  }

  public getUser(): BehaviorSubject<User> {
    return this.user$;
  }

  public setUser(user: User) {
    this.user$.next(user);
  }

  public logout(): Promise<void> {
    return this.keycloak.logout();
  }
}
