import {AdvancedHttpClient, WebDataRepository} from '@drip/core';
import {
  Consignment,
  ConsignmentDetails,
  ConsignmentDetailsCompat,
  ConsignmentList,
  ConsignmentListCompat
} from '@app-models';
import {IConsignmentRepository} from './IConsignmentRepository';
import {map, Observable} from 'rxjs';
import {ConsignmentMapper} from '../../../utilities/consignment-mapper';

export class ConsignmentRepository extends WebDataRepository<Consignment> implements IConsignmentRepository {
  constructor(protected override httpClient: AdvancedHttpClient) {
    super(httpClient, 'consignments');
  }

  /**
   * Get data for details page when user came from permLink
   *
   * @param permLinkIdentifier string
   * @returns Observable<any>
   */
  public getConsignmentsDetailsFromPermLink(
    permLinkIdentifier: string
  ): Observable<ConsignmentDetails> {
    return this.httpClient.get({
      url: `${this.resource}/pl/${permLinkIdentifier}`
    });
  }

  /**
   * Get Consignment list for the user
   *
   * @param fromTimestamp number
   * @param toTimestamp number
   * @param limit number
   * @param offset number
   * @param search string
   * @param sort string
   *
   * @returns Observable<ConsignmentList>
   */
  public getConsignments(
    fromTimestamp: number,
    toTimestamp: number,
    limit: number,
    offset: number,
    search: string,
    sort: string
  ): Observable<ConsignmentList> {
    return this.httpClient.get<ConsignmentListCompat>({
      url: `${this.resource}`,
      headers: {
        singletonLoader: offset != 0 ? 'true' : 'false'
      },
      queryParams: {
        limit,
        offset,
        fromTimestamp,
        toTimestamp,
        search,
        sort,
      }
    }).pipe(map(list => ConsignmentMapper.mapConsignmentList(list)));
  }

  /**
   * Fetches the exported File from backend API. Uses the same parameters as request to fetch data regularly
   * @param fromTimestamp
   * @param toTimestamp
   * @param search
   * @param sort
   */
  public getExport(
    fromTimestamp: number,
    toTimestamp: number,
    search: string,
    sort: string
  ): Observable<Blob> {
    return this.httpClient.get({
      url: `${this.resource}/export`,
      headers: {
        singletonLoader: 'true'
      },
      responseType: 'blob',
      queryParams: {
        fromTimestamp,
        toTimestamp,
        search,
        sort,
      }
    });
  }

  /**
   * Get Consignment's details
   *
   * @param consignmentId string
   * @returns Observable<ConsignmentDetails>
   */
  public getConsignmentDetails(
    consignmentId: string
  ): Observable<ConsignmentDetails> {
    return this.httpClient.get<ConsignmentDetailsCompat>({
      url: `${this.resource}/${consignmentId}`,
    }).pipe(map(cons => ConsignmentMapper.mapConsignmentDetails(cons)));
  }
}
