import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LoaderService {
  private loaderCounter: number = 0;

  private readonly showLoaderSrc$ = new BehaviorSubject<boolean>(false);
  public readonly showLoader$ = this.showLoaderSrc$.asObservable();

  constructor() {}
  /**
   * Increases the loader counter and shows the loader.
   *
   * @returns void
   */
  public showLoader(): void {
    this.loaderCounter++;
    this.showLoaderSrc$.next(true);
  }
  /**
   * Decreases the loader counter and hides the loader if the counter becomes zero.
   *
   * @returns void
   */
  public hideLoader(): void {
    if (this.loaderCounter > 0) {
      this.loaderCounter--;
    }
    if (this.loaderCounter <= 0) {
      this.showLoaderSrc$.next(false);
    }
  }
}
