import {ICustomerPortalEnvironment} from './ICustomerPortalEnvironment';
import {EnvironmentStage, SemanticVersionString} from '@drip/core';
import packageJson from '../../../../package.json';

export const environment: ICustomerPortalEnvironment = {
  stage: EnvironmentStage.LOCAL,
  version: packageJson.version as SemanticVersionString,
  api: {
    backendUrl: 'https://api.qa.drip-log.com/cp',
  },
  config: {
    configFile: 'assets/config/config.dev.json',
  },
  i18n: {
    defaultLanguageCode: 'en',
    availableLanguages: [
      {name: 'English', code: 'en'},
      {name: 'Deutsch', code: 'de'},
    ]
  }
};
