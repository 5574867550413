import {HttpClient} from '@angular/common/http';
import {DataService} from './DataService';
import {ICustomerPortalEnvironment} from '@app-environments/ICustomerPortalEnvironment';
import {UserRepository} from './repositories/user/UserRepository';
import {ApiClient} from '@drip/core';
import {ConsignmentRepository} from './repositories/consignments/ConsignmentRepository';

export class WebApiDataService extends DataService {
  constructor(
    private readonly httpClient: HttpClient,
    private readonly environment: ICustomerPortalEnvironment,
  ) {
    super();
    const apiClient = new ApiClient(environment.api.backendUrl, httpClient);
    this.users = new UserRepository(apiClient);
    this.consignments = new ConsignmentRepository(apiClient);
  }
}
