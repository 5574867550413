import { Observable } from 'rxjs';
import { AdvancedHttpClient } from '../../http/AdvancedHttpClient';
import { Filter, Pagination, Sort } from '../../types';
import { IRepository } from './IRepository';
import { EntityId } from '../../../types';
import { Utils } from '../../../utils';
import { BaseModel } from '../../../models';

export class WebDataRepository<T extends BaseModel> implements IRepository<T> {
  constructor(
    protected httpClient: AdvancedHttpClient,
    protected resource: string
  ) {
  }

  public getAll(filters?: Filter[], sort?: Sort<T>[], pagination?: Pagination, path?: string): Observable<T[]> {
    return this.httpClient.get<T[]>({
      url: Utils.joinPaths(this.resource, path)
    });
  }

  public getById(id: EntityId): Observable<T> {
    return this.httpClient.get<T>({
      url: `${this.resource}/${id}`
    });
  }
}
