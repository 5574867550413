import {HttpClient, HttpContext} from '@angular/common/http';
import {map} from 'rxjs/operators';
import {Utils} from '../../utils';

export type RequestParams = { [s: string]: string | number | string[] | number[] };

export type RequestData<B> = {
  method?: 'GET' | 'PUT' | 'POST' | 'DELETE' | 'PATCH';
  url: string;
  body?: B;
  headers?: { [s: string]: string };
  queryParams?: RequestParams;
  version?: string;
  context?: HttpContext;
  responseType?: 'json' | 'blob';
};

export abstract class AdvancedHttpClient {
  protected constructor(public httpClient: HttpClient) {
  }

  protected abstract get baseUri(): string;

  protected abstract get defaultVersion(): string;

  public get<T>(data: RequestData<void>) {
    return this.request<T, void>({ ...data, method: 'GET' });
  }

  public put<T, B>(data: RequestData<B>) {
    return this.request<T, B>({ ...data, method: 'PUT' });
  }

  public post<T, B>(data: RequestData<B>) {
    return this.request<T, B>({ ...data, method: 'POST' });
  }

  public patch<T, B>(data: RequestData<B>) {
    return this.request<T, B>({ ...data, method: 'PATCH' });
  }

  public delete<T>(data: RequestData<void>) {
    return this.request<T, void>({ ...data, method: 'DELETE' });
  }

  public downloadFile(url: string, queryParams?: RequestParams) {
    return this.requestFile({ method: 'GET', url, queryParams });
  }

  protected request<T, B>(data: RequestData<B>) {
    return this.httpClient
      .request(data.method!, this.buildUrl(data), {
        headers: data.headers,
        params: data.queryParams,
        body: data.body,
        context: data.context,
        responseType: data.responseType ?? 'json',
        observe: 'response'
      })
      .pipe(map((event) => event.body as T));
  }

  protected requestFile(data: RequestData<void>) {
    return this.httpClient.get(this.buildUrl(data), {
      responseType: 'blob'
    });
  }

  protected buildUrl<B>(data: RequestData<B>) {
    const finalVersion: string = data?.version || this.defaultVersion;
    return Utils.joinPaths(this.baseUri, finalVersion, data.url);
  }
}
