import {
  Consignment,
  ConsignmentCompat,
  ConsignmentDetails,
  ConsignmentDetailsCompat,
  ConsignmentList,
  ConsignmentListCompat,
  LocationInformation
} from '@app-models';

export class ConsignmentMapper {
  public static mapConsignmentList(list: ConsignmentListCompat): ConsignmentList {
    return {
      totalCount: list.totalCount,
      consignments: list.consignments.map(cons => ConsignmentMapper.mapConsignment(cons))
    };
  }

  public static mapConsignment(cons: ConsignmentCompat): Consignment {
    const compatReceiver = cons.receiver;
    if (!compatReceiver) {
      return {
        ...cons,
        receiver: compatReceiver
      }
    }
    if ('address' in compatReceiver) {
      return {
        ...cons,
        receiver: compatReceiver
      }
    }
    const mappedReceiver: LocationInformation = {
      actualDate: compatReceiver.unloadingDate,
      address: compatReceiver.unloadingAddress,
      plannedDate: compatReceiver.plannedUnloadingDate
    };
    return {
      ...cons,
      receiver: mappedReceiver
    }
  }

  public static mapConsignmentDetails(cons: ConsignmentDetailsCompat): ConsignmentDetails {
    if (!('unloadingInfo' in cons)) {
      return cons;
    }
    if (!cons.unloadingInfo) {
      return cons;
    }
    return {
      ...cons,
      receiver: {
        plannedDate: cons.unloadingInfo.plannedUnloadingDate,
        actualDate: cons.unloadingInfo.unloadingDate,
        address: {
          name: cons.unloadingInfo.receiverName,
          ...cons.unloadingInfo.unloadingAddress
        }
      }
    }
  }
}
