export abstract class ArrayUtils {
  public static removeDuplicates<T>(array: T[]): T[] {
    return Array.from(new Set(array));
  }

  public static filterNullOrUndefined<T>(value: T | undefined | null): value is T {
    return value !== undefined && value !== null;
  }

  public static concatArrays<T>(...arrays: (T[] | undefined)[]): T[] {
    return arrays.filter((array): array is T[] => array !== undefined).reduce((previousValue, currentValue) => previousValue.concat(currentValue), []);
  }

  public static max<T>(array: T[] | undefined, getValue: (array: T) => number, defaultIfUndefinedOrEmpty = 0): number {
    if (array?.length) {
      return array.reduce((accumulator, currentValue) => {
        return Math.max(accumulator, getValue(currentValue));
      }, getValue(array[0]));
    }
    return defaultIfUndefinedOrEmpty;
  }

  public static groupBy<T>(array: T[], selector: (element: T) => string | number | null | undefined) {
    const result: {
      [key: string | number]: T[];
    } = {};

    array.forEach((element) => {
      let key = selector(element);
      if (!key) {
        key = String(key);
      }

      if (!result[key]) {
        result[key] = [];
      }
      result[key].push(element);
    });

    return result;
  }

  public static sortBy<T>(array: T[] | undefined, ...properties: [keyof T, 'asc' | 'desc'][]): T[] | undefined {
    return array?.sort((a, b) => {
      for (const [property, direction] of properties) {
        if (a[property] < b[property]) {
          return direction === 'asc' ? -1 : 1;
        }
        if (a[property] > b[property]) {
          return direction === 'asc' ? 1 : -1;
        }
      }
      return 0;
    });
  }
}
