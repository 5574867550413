import { ArrayUtils } from './ArrayUtils';

export abstract class Utils {
  /**
   * Combine paths and make sure two slashes aren't in sequence
   */
  public static joinPaths(...paths: Array<string | undefined>): string {
    return paths
      .filter((path) => ArrayUtils.filterNullOrUndefined(path))
      .map((path, i) => {
        if (i === 0) {
          return path?.trim().replace(/\/*$/g, '');
        } else {
          return path?.trim().replace(/(^\/*|\/*$)/g, '');
        }
      })
      .filter((path) => path?.length)
      .join('/');
  }

  public static clone<T>(obj: T): T;
  public static clone<T>(obj?: T): T | undefined {
    if (obj != null) {
      return JSON.parse(JSON.stringify(obj));
    }
    return undefined;
  }
}
