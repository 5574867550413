import {Observable} from 'rxjs';
import {
  AdvancedHttpClient,
  EntityId,
  Filter,
  IUserRepository,
  Pagination,
  RefreshTokenDto,
  Sort,
  User,
  WebDataRepository
} from '@drip/core';

export class UserRepository extends WebDataRepository<User> implements IUserRepository {
  constructor(public override httpClient: AdvancedHttpClient) {
    super(httpClient, 'users');
  }

  public login(email: string, password: string): Observable<User> {
    return this.httpClient.post<User, { email: string; password: string }>({
      url: `${this.resource}/auth`,
      body: {email, password}
    });
  }

  public resetPassword(email: string): Observable<void> {
    return this.httpClient.post({
      url: `${this.resource}/reset-password`,
      body: {email}
    })
  }

  public logout(): Observable<void> {
    return this.httpClient.post<void, void>({url: `${this.resource}/logoutFromAsset`});
  }

  public refreshTokens(refreshToken: string): Observable<RefreshTokenDto> {
    return this.httpClient.post<RefreshTokenDto, { refresh_token: string }>({
      url: `${this.resource}/refresh-token`,
      body: {refresh_token: refreshToken}
    });
  }

  // eslint-disable-next-line no-unused-vars
  public override getAll(filters?: Filter[], sort?: Sort<User>[], pagination?: Pagination): Observable<User[]> {
    throw new Error('Method not supported.');
  }

  // eslint-disable-next-line no-unused-vars
  public override getById(id: EntityId): Observable<User> {
    throw new Error('Method not supported.');
  }
}
