import {Injectable} from '@angular/core';
import {BehaviorSubject, firstValueFrom, map, Observable, switchMap,} from 'rxjs';
import {KeycloakService} from 'keycloak-angular';
import {ConfigInitService} from '../init';
import {fromPromise} from 'rxjs/internal/observable/innerFrom';
import {Title} from '@angular/platform-browser';

export enum ValidTenants {
  DRIP = 'drip',
  DONAULAGER = 'donaulager',
  KEIMELMAYR = 'keimelmayr',
  INTERNORM = 'internorm',
}

type BrandingData = {
  [key in ValidTenants]: {
    readableName: string;
    contactMail: string;
    brandColor: string;
  };
};

@Injectable({
  providedIn: 'root',
})
export class BrandingService {
  /**
   * Hardcoded for now. Should come from API in the future
   * @private
   */
  private readonly brandingData: BrandingData = {
    [ValidTenants.DRIP]: {
      readableName: 'Drip',
      contactMail: 'office@d3eif.com',
      brandColor: '#E0004D',
    },
    [ValidTenants.DONAULAGER]: {
      readableName: 'Donaulager',
      contactMail: 'donaulager@linzag.at',
      brandColor: '#2E5DAD',
    },
    [ValidTenants.KEIMELMAYR]: {
      readableName: 'Keimelmayr',
      contactMail: 'spedition@keimelmayr.at',
      brandColor: '#76b830',
    },
    [ValidTenants.INTERNORM]: {
      readableName: 'Internorm',
      contactMail: 'office@internorm.at',
      brandColor: '#CC0404',
    },
  };

  private readonly tenantId$ = new BehaviorSubject<ValidTenants>(
    ValidTenants.DRIP
  );

  public get tenantId(): ValidTenants {
    return this.tenantId$.value;
  }

  constructor(
    private readonly keyCloakService: KeycloakService,
    private readonly configService: ConfigInitService,
    private readonly title: Title
  ) {}

  public getLogoUrl(): Observable<string> {
    return this.tenantId$.pipe(
      map((tenantId) => `/assets/branding/${tenantId}/logo.png`)
    );
  }

  public getReadableName(): Observable<string> {
    return this.tenantId$.pipe(
      map((tenantId) => this.brandingData[tenantId].readableName),
    );
  }

  public getLoginBackgroundUlr(): Observable<string> {
    return this.tenantId$.pipe(
      map((tenantId) => `/assets/branding/${tenantId}/background.png`)
    );
  }

  public getContactBackgroundUrl(): Observable<string> {
    return this.tenantId$.pipe(
      map((tenantId) => `/assets/branding/${tenantId}/contact.png`)
    );
  }

  public getContactMail() {
    return this.tenantId$.pipe(
      map((tenantId) => this.brandingData[tenantId].contactMail)
    );
  }

  public init(): Promise<boolean> {
    let urlTenant: ValidTenants | undefined;
    let domainTenant: ValidTenants | undefined;
    const urlParts = window.location.href.split('#')[0].split('/');
    const hostParts = window.location.hostname.split('.');
    for (const tenantId of Object.values(ValidTenants)) {
      const urlMatch = urlParts.includes(tenantId);
      const domainMatch = hostParts.includes(tenantId);
      if (domainMatch) {
        domainTenant = tenantId;
      }
      if (urlMatch) {
        urlTenant = tenantId
      }
    }
    const finalTenant = domainTenant ?? urlTenant;
    return this.setTenant(finalTenant);
  }

  public setTenant(tenant?: ValidTenants): Promise<boolean> {
    const configPromise = firstValueFrom(
      this.configService.getConfig().pipe(
        switchMap((config) => {
          return fromPromise(
            this.keyCloakService.init({
              config: {
                url: config!['KEYCLOAK_URL'],
                realm: config!['KEYCLOAK_REALM'],
                clientId: config!['KEYCLOAK_CLIENT_ID'],
              },
              initOptions: {
                checkLoginIframe: false,
              },
              shouldAddToken: (request) => {
                // Exclude token for .json files
                const isJsonFile = request.url.endsWith('.json');

                return !isJsonFile;
              },
            })
          );
        })
      )
    );
    if (!tenant) {
      this.tenantId$.next(ValidTenants.DRIP);
    } else {
      this.tenantId$.next(tenant);
    }
    this.setTitle();
    this.setStyles();
    return configPromise;
  }

  private setStyles() {
    const primaryColor = this.brandingData[this.tenantId].brandColor;
    document.body.style.setProperty('--brand-color-500', primaryColor);
    document.body.style.setProperty('--brand-color-200', `${primaryColor}54`);
    document.body.style.setProperty('--brand-color-100', `${primaryColor}38`);
  }

  private setTitle() {
    const newTitle = `${
      this.brandingData[this.tenantId].readableName
    } Customer Portal`;
    this.title.setTitle(newTitle);
  }
}
