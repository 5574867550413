import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {mergeMap, Observable, of} from 'rxjs';
import {environment} from '../../../../environments/environment';
import {KeycloackConfig} from '@app-models';

@Injectable({
  providedIn: 'root',
})
export class ConfigInitService {
  private config: KeycloackConfig;

  constructor(private httpClient: HttpClient) {}

  /**
   * Fetch config file from assets. Path to config file is defined in environment file
   */
  public getConfig(): Observable<KeycloackConfig | null> {
    return this.httpClient
      .get(this.getConfigFile(), {
        observe: 'response',
      })
      .pipe(
        mergeMap((response) => {
          if (response && response.body) {
            this.config = response.body as KeycloackConfig;
            return of(this.config);
          } else {
            return of(null);
          }
        })
      );
  }

  /**
   * Get File path to config file
   * @private
   */
  private getConfigFile(): string {
    return environment.config.configFile;
  }
}
