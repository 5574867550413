import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HeaderService {

  private readonly showHeaderSrc$ = new BehaviorSubject<boolean>(false);
  public readonly showHeader$ = this.showHeaderSrc$.asObservable();

  public setShowHeader(show: boolean) {
    this.showHeaderSrc$.next(show);
  }
}
