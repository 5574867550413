import {Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class LanguageService {
  private readonly supportedLanguages = ['en', 'de'];
  private readonly defaultLanguage = 'en';

  constructor(private translationService: TranslateService) {}

  /**
   * Check for chosen browser languages
   * Select first supported language as default, if none found set default lang English
   *
   * @returns void
   */
  public init(): void {
    const browserLanguages = navigator.languages.map((x) => x.substring(0, 2));
    const firstSupportedLanguage = browserLanguages.find((language) =>
      this.supportedLanguages.includes(language)
    );
    this.translationService.setDefaultLang(
      firstSupportedLanguage ?? this.defaultLanguage
    );
    this.translationService.use(firstSupportedLanguage ?? this.defaultLanguage);
  }
}
